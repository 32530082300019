$(document).on('turbolinks:load', function() {
    $(document).on('change', '#product-server-table.datatable td .period', function () {
        var period = $(this).val();
        var current_row = $(this).closest('tr');
        var current_total_cell = $('.total', current_row);
        var total = current_total_cell.data('currency') + (period * current_total_cell.data('price')).toFixed(2);
        current_total_cell.text(total);
        calc_total(current_row);
    });

    $('#product-server-table.datatable').on('change', '.durationpicker-duration', function () {
        var current_row = $(this).closest('tr');
        var periodCell = $('input.period', current_row);
        var period = parsePeriod(periodCell.val());
        if (period < 30) {
            $('#duration-minutes', current_row).val(30);
            periodCell.val(30);
            period = 30;
        }
        var current_total_cell = $('.total', current_row);
        var total = current_total_cell.data('currency') + (period * current_total_cell.data('price')).toFixed(2);
        current_total_cell.text(total);
        calc_total(current_row);
    });

    $(document).on('click', '#product-server-table.datatable input[type="submit"]', function () {
        var form = $('form.order-submit');
        var button = $(this);
        var productId = button.data('product-id');
        var periodType = button.data('period-type');
        var current_row = button.closest('tr');
        var period = parsePeriod($('input.period', current_row).val());
        var os = $('select.os :selected', current_row).val();

        if (period == 0) {
            if (periodType == 'minute') {
                period = 30;
            } else if (periodType == 'week' || periodType == 'month') {
                period = 1;
            }
        }

        form.find('input#order_nomenclature_id').val(productId);
        form.find('input#prolong_nomenclature_id').val(productId);
        form.find('input#order_period_count').val(parsePeriod(period));
        form.find('input#prolong_period_count').val(parsePeriod(period));
        if (!os) {
            form.find('input#order_os').val($("#os_value_prolong").text());
        } else {
            form.find('input#order_os').val(os);
        }
        form.submit();
    });

    function calc_total(current_row){
        var periodCell = $('input.period', current_row);
        var period = parsePeriod(periodCell.val());
        var current_total_cell = $('.total', current_row);
        var total = (period * current_total_cell.data('price')).toFixed(2); 
        var enabled = total >= 10.0;
        if(enabled){
            $('input[type="submit"]', current_row).prop('disabled', false);
            $('input.period', current_row).removeClass('with-error');
        }
        else{
            $('input[type="submit"]', current_row).prop('disabled', true);
            $('input.period', current_row).addClass('with-error');
        }
    }

    function parsePeriod(input){
        if (input == null || input === '') return 0;
        srx = new RegExp(/^[0-9]+[.]?[0-9]*$/);
        mrx = new RegExp(/([0-9][0-9]?)[ ]?m/);
        hrx = new RegExp(/([0-9][0-9]?)[ ]?h/);
        drx = new RegExp(/([0-9][0-9]?)[ ]?d/);
        days = 0;
        hours = 0;
        minutes = 0;
        if (mrx.test(input)) {
            minutes = mrx.exec(input)[1];
        }
        if (hrx.test(input)) {
            hours = hrx.exec(input)[1];
        }
        if (drx.test(input)) {
            days = drx.exec(input)[1];
        }

        if (srx.test(input)) {
            return parseFloat(input);
        } else {
            return to_minutes(days, hours, minutes);
        }
    }

    function to_minutes(dd,hh,mm) {
        d = parseInt(dd);
        h = parseInt(hh);
        m = parseInt(mm);
        if (isNaN(d)) d = 0;
        if (isNaN(h)) h = 0;
        if (isNaN(m)) m = 0;

        t = d * 24 * 60 +
            h * 60 +
            m;
        return t;
    }

    $('input.period[type=text]').each(function () {
        $(this).durationPicker({
            days: {
                label: 'd',
                min: 0,
                max: 30
            },
            hours: {
                label: 'h',
                min: 0,
                max: 23
            },
            minutes: {
                label: 'm',
                min: 0,
                max: 59
            },
            classname: "form-control input-sm",
            type: 'number',
            responsive: true
        });
    });
});
