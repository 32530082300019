$(document).ready(function () {
    var csr = $('#our_ssl_certificate_csr').val();

    if (csr) {
        $('#ssl_subscription').attr('disabled', false);
    } else {
        $('#ssl_subscription').attr('disabled', true);
    }

    $("#our_ssl_certificate_csr").bind('input propertychange', function() {
        var csr_val = $('#our_ssl_certificate_csr').val();

        if (csr_val) {
            $('#ssl_subscription').attr('disabled', false);
        } else {
            $('#ssl_subscription').attr('disabled', true);
        }
    });
})
