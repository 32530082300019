$(document).on('turbolinks:load', function() {
    if ($('#services-info-tables').is(":visible")) {
        $.ajax({
            url: window.location.pathname + "/sessions",
            type: 'GET',
            dataType: 'JSON',
            crossDomain: true,
            success: function (sessions) {
                if (sessions.length > 0) {
                    $('#sessions-block').css("visibility", "visible");
                    sessionsTable = createTable(sessions, Object.keys(sessions[0]));
                    $('#sessions-data').append(sessionsTable)
                }
            }
        });

        $.ajax({
            url: window.location.pathname + "/commands",
            type: 'GET',
            dataType: 'JSON',
            crossDomain: true,
            success: function (commands) {
                if (commands.length > 0) {
                    $('#commands-block').css("visibility", "visible");
                    commandsTable = createTable(commands, Object.keys(commands[0]));
                    $('#commands-data').append(commandsTable)
                }
            }
        });
    }

    function createTable(data, fields) {
        var table = '';
        data.forEach(function (item) {
            table += '<tr>';
            fields.forEach(function (field) {
                table += "<td>" + item[field] + "</td>";
            });
            table += '</tr>';
        });
        return table;
    }
});