// Generated by CoffeeScript 1.9.1
(function() {
  var API_URL, SSLActivation, SSLActivationHelper, SSLActivationView,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  API_URL = window.API_URL_LINK;

  SSLActivationHelper = (function() {
    var TRANSLATES;

    TRANSLATES = {
      en: {
        form: {
          first_name: 'First name*',
          last_name: 'Last name*',
          phone: 'Phone*',
          email: 'Email*',
          title: 'Title',
          fax: 'Fax',
          company: 'Company*',
          address_1: 'Address*',
          address_2: 'Address 2',
          city_name: 'City*',
          region_name: 'Region*',
          zip_code: 'Zip code*',
          country: 'Country*',
          duns: 'DUNS number or link to company profile in online directory',
          inn: 'INN*',
          next: 'Next',
          error: 'Fill all fields with *',
          contact_info_title: 'Contact info',
          certificate_title: 'Generate CSR',
          server_software: 'Server software*',
          csr: 'Paste your CSR*',
          domain: 'Domain name*',
          additional_domains: 'Additional domains',
          organisation_name: 'Organisation name*',
          organisation_unit: 'Organisation unit*',
          select_prompt: 'Select software',
          wrong_csr: 'CSR request invalid',
          error_csr: 'Error! Regenerate CSR',
          wildcard_error: 'Domain must be wildcard (*.example.com)',
          no_wildcard_error: 'Domain should not be wildcard'
        },
        simple_way: 'Simple way',
        pro_way: 'Professional way',
        domains_available: {
          standard: 'Standard domains are available for add:',
          wildcard: 'Wildcard domains are available for add:'
        },
        dcv: {
          title: 'Domain control validation',
          description: 'All certificates must pass through DCV (Domain Control Validation) before they are issued. DCV is a mechanism used to prove ownership or control of a registered domain name. Please choose method of DCV:',
          instruction: 'Instructions to complete your DNS CNAME-based Domain Control Validation and yours hashes will be sent to',
          domain: 'Domain name',
          validation_email: 'Email for validations',
          email: {
            title: 'Validation by e-mail',
            descrtiption: 'Please select the approved email address to which you would like us to send the domain control validation email',
            error: 'Choose email for validation',
            whois: 'Whois',
            level_2: 'Level 2',
            level_3: 'Level 3'
          },
          http: {
            title: 'HTTP Based DCV',
            description: 'Your CSR will be hashed. The hash values are provided to you and you must create a simple plain-text file and place this in the root of your webserver The file and it\'s content should be as follows:',
            file_name: 'File name:',
            file_content: 'File content:'
          },
          dns: {
            title: 'DNS CNAME Based DCV',
            description: 'Your CSR will be hashed. The hash values are provided to you and must be entered as a  DNS CNAME record for your domain. The hashes are to be entered as follows:'
          }
        },
        finish: {
          thx: 'Thank you for your order!',
          recall: 'We will contact you soon.'
        },
        not_found_cert: 'The certificate has been confirmed or not found'
      },
      ru: {
        form: {
          first_name: 'Имя*',
          last_name: 'Фамилия*',
          phone: 'Телефон*',
          email: 'Эл. почта*',
          title: 'Должность',
          fax: 'Факс',
          company: 'Организация*',
          address_1: 'Адрес*',
          address_2: 'Адрес (продолжение)',
          city_name: 'Город*',
          region_name: 'Область*',
          zip_code: 'Индекс*',
          country: 'Страна*',
          next: 'Далее',
          error: 'Заполните все поля с *',
          contact_info_title: 'Контактная информация',
          certificate_title: 'Генерация CSR',
          server_software: 'Серверное ПО*',
          inn: 'ИНН*',
          duns: 'DUNS номер или ссылка на страницу компании в интернет-справочнике',
          csr: 'Вставьте Ваш CSR*',
          domain: 'Домен*',
          organisation_name: 'Организация*',
          organisation_unit: 'Отдел*',
          additional_domains: 'Дополнительные домены',
          select_prompt: 'Выберите ПО',
          wrong_csr: 'CSR запрос не валидный',
          error_csr: 'Ошибка! Cгенерируйте новый CSR',
          wildcard_error: 'Домен должен быть wildcard (*.example.com)',
          no_wildcard_error: 'Домен не должен быть wildcard'
        },
        simple_way: 'Простой способ',
        pro_way: 'Профессиональный способ',
        domains_available: {
          standard: 'Доступно стандартных доменов для добавления:',
          wildcard: 'Доступно wildcard доменов для добавления:'
        },
        dcv: {
          title: 'Проверка по домену',
          description: 'Для выпуска SSL-сертификата, согласно требованиям всех центров сертификации в мире, требуется пройти проверку по доменному имени. В ходе проверки подтверждается возможнось контроля зарегистрированного доменного имени. Пожалуйста, выберите способ проверки:',
          instruction: 'Более подробные инструкции по завершению проверки домена и значения проверочных хэшей будут высланы на email',
          domain: 'Доменное имя',
          validation_email: 'Email для валидации',
          email: {
            title: 'Проверка по e-mail',
            descrtiption: 'Пожалуйста, выберите e-mail адрес, на который Вам бы хотелось получить проверочное письмо.',
            error: 'Выберите email для проверки',
            whois: 'Whois',
            level_2: 'Уровень 2',
            level_3: 'Уровень 3'
          },
          http: {
            title: 'HTTP Based DCV',
            description: 'Из Вашего CSR будут сформированы хэши. Вам нужно будет создать простой текстовый файл и разместить его в корне Вашего web-сервера, сделав доступным через HTTP. Название файла и его содержание должны быть следующими:',
            file_name: 'Имя файла:',
            file_content: 'Содержимое файла:'
          },
          dns: {
            title: 'DNS CNAME Based DCV',
            description: 'Из Вашего CSR будут сформированы хэши. Значения хэшей должны быть указаны в записи DNS CNAME для Вашего домена в виде'
          }
        },
        finish: {
          thx: 'Благодарим за заказ!',
          recall: 'Мы свяжемся с Вами в ближайшее время.'
        },
        not_found_cert: 'Сертификат уже подтвержден или не найден'
      }
    };

    function SSLActivationHelper(language) {
      this.sslServerSoftwareSelect = bind(this.sslServerSoftwareSelect, this);
      this.fetchSSLServerSoftwares = bind(this.fetchSSLServerSoftwares, this);
      this.fetchCountries = bind(this.fetchCountries, this);
      this.t = bind(this.t, this);
      this.countrySelect = bind(this.countrySelect, this);
      this.language = language;
      this.fetchCountries();
    }

    SSLActivationHelper.prototype.countrySelect = function(name, selected) {
      var country, i, label, len, ref, select;
      label = "<div class='form-group'><label for='" + name + "' class='form-group__label'>" + (this.t('form.country')) + "</label>";
      select = "<select name='" + name + "' required='true' class='form-group__select'>";
      ref = this.countries;
      for (i = 0, len = ref.length; i < len; i++) {
        country = ref[i];
        select += "<option value='" + country.isocode + "' " + (country.isocode === selected ? 'selected' : '') + ">" + country.name + "</option>";
      }
      select += '</select></div>';
      return label + select;
    };

    SSLActivationHelper.prototype.t = function(keys) {
      return eval("TRANSLATES." + this.language + "." + keys);
    };

    SSLActivationHelper.prototype.fetchCountries = function() {
      return $.getJSON(API_URL + "/" + this.language + "/countries.json", (function(_this) {
        return function(data) {
          var i, isocode, item, j, len, len1, ref;
          _this.countries = [];
          if (window.top_countries != null) {
            ref = window.top_countries;
            for (i = 0, len = ref.length; i < len; i++) {
              isocode = ref[i];
              for (j = 0, len1 = data.length; j < len1; j++) {
                item = data[j];
                if (item.isocode === isocode) {
                  _this.countries.push(item);
                }
              }
            }
          }
          return $.unique($.merge(_this.countries, data));
        };
      })(this));
    };

    SSLActivationHelper.prototype.fetchSSLServerSoftwares = function(issuer) {
      return $.getJSON(API_URL + "/ssl_server_softwares.json?issuer=" + issuer, (function(_this) {
        return function(data) {
          return _this.ssl_server_softwares = data;
        };
      })(this));
    };

    SSLActivationHelper.prototype.sslServerSoftwareSelect = function(selected) {
      var i, label, len, ref, select, software;
      label = "<div class='form-group'><label for='ssl_server_software_id' class='form-group__label'>" + (this.t('form.server_software')) + "</label>";
      select = "<select name='ssl_server_software_id' class='form-group__select' required='true'>";
      select += "<option value=''>" + (this.t('form.select_prompt')) + "</option>";
      ref = this.ssl_server_softwares;
      for (i = 0, len = ref.length; i < len; i++) {
        software = ref[i];
        if (software.id !== 1) {
          select += "<option value='" + software.id + "' " + (software.id === selected ? 'selected' : '') + ">" + software.name + "</option>";
        }
      }
      select += "</select></div>";
      return label + select;
    };

    SSLActivationHelper.prototype.formatField = function(value) {
      if (value != null) {
        return value;
      } else {
        return new String();
      }
    };

    return SSLActivationHelper;

  })();

  SSLActivationView = (function() {
    var IIS_ids, UCC_ids;

    UCC_ids = [50, 279, 296, 305];

    IIS_ids = [14, 15, 37, 38, 50, 51, 68];

    function SSLActivationView(lang) {
      this.clearAlerts = bind(this.clearAlerts, this);
      this.appendAlert = bind(this.appendAlert, this);
      this.showCriticalError = bind(this.showCriticalError, this);
      this.showTextAlert = bind(this.showTextAlert, this);
      this.showAlert = bind(this.showAlert, this);
      this.createIframe = bind(this.createIframe, this);
      this.changeDCVType = bind(this.changeDCVType, this);
      this.getAdditionalDCV = bind(this.getAdditionalDCV, this);
      this.renderFinish = bind(this.renderFinish, this);
      this.renderDCV = bind(this.renderDCV, this);
      this.buildSimpleCSRForm = bind(this.buildSimpleCSRForm, this);
      this.buildProCSRForm = bind(this.buildProCSRForm, this);
      this.changeSSLServerSoftware = bind(this.changeSSLServerSoftware, this);
      this.changeCSRWay = bind(this.changeCSRWay, this);
      this.additionalDomainsAvailableInfo = bind(this.additionalDomainsAvailableInfo, this);
      this.renderCertificateDetails = bind(this.renderCertificateDetails, this);
      this.renderContactInfo = bind(this.renderContactInfo, this);
      this.setServerSoftwares = bind(this.setServerSoftwares, this);
      this.container = $('div#ssl_activation');
      this.helper = new SSLActivationHelper(lang);
    }

    SSLActivationView.prototype.setServerSoftwares = function(issuer) {
      return this.helper.fetchSSLServerSoftwares(issuer);
    };

    SSLActivationView.prototype.renderContactInfo = function(order) {
      var field, fields, html, i, len;
      html = "<div class='heading'><h2 class='heading__title'>" + (this.helper.t('form.contact_info_title')) + "</h2></div><form id='contact_info' class='form'>";
      fields = ["first_name", "last_name", "phone", "email"];
      for (i = 0, len = fields.length; i < len; i++) {
        field = fields[i];
        html += "<div class='form-group'><label for='" + field + "' class='form-group__label'>" + (this.helper.t('form.' + field)) + "</label>";
        html += "<input type='text' name='" + field + "' value='" + (this.helper.formatField(order[field])) + "' class='form-group__input_text' /></div>";
      }
      html += this.helper.countrySelect('country_id', order.country_id);
      html += "<div><span class='error'></span></div>";
      html += "<div class='form-group'><input type='submit' name='' class='form-group__submit' value='" + (this.helper.t('form.next')) + "'></div></form>";
      return this.container.html(html);
    };

    SSLActivationView.prototype.renderCertificateDetails = function(order) {
      var html;
      html = "<div class='heading'><h2 class='heading__title'>" + (this.helper.t('form.certificate_title')) + "</h2></div>";
      html += "<div id='form_container' class='form-container'><div><span class='error'></span></div>";
      html += this.buildProCSRForm(order, false);
      html += "</div>";
      this.container.html(html);
      $('input:radio[name="way"]', this.container).change(this.changeCSRWay);
      $('select[name="ssl_server_software_id"]', this.container).change(this.changeSSLServerSoftware);
      $('form#simple_csr select[name="country_name"]', this.container).change(function() {
        if ($(this).val() === 'RU') {
          $('form#simple_csr div#inn_field').show();
          return $('form#simple_csr div#inn_field input[name="inn"]').prop({
            required: true
          });
        } else {
          $('form#simple_csr div#inn_field').hide();
          $('form#simple_csr div#inn_field input[name="inn"]').val('');
          return $('form#simple_csr div#inn_field input[name="inn"]').prop({
            required: false
          });
        }
      });
      return $('form#simple_csr select[name="country_name"]', this.container).change();
    };

    SSLActivationView.prototype.additionalDomainsAvailableInfo = function(order) {
      var domains, html, ref, standard_count, standard_domains_available, wildcard_count, wildcard_domains_available;
      html = '';
      if (parseInt(order.items[0].standard_domains_count) > 0 || parseInt(order.items[0].wildcard_domains_count) > 0) {
        domains = (order.items[0].additional_domains != null) && order.items[0].additional_domains !== '' ? order.items[0].additional_domains.split(',') : [];
        if (ref = order.items[0].domain_name, indexOf.call(domains, ref) < 0) {
          domains = domains.concat(order.items[0].domain_name);
        }
        wildcard_count = SSLActivation.countWildcardDomains(domains);
        standard_count = domains.length - wildcard_count;
        standard_domains_available = order.items[0].standard_domains_count - standard_count;
        if (standard_domains_available < 0) {
          standard_domains_available = 0;
        }
        wildcard_domains_available = order.items[0].wildcard_domains_count - wildcard_count;
        if (wildcard_domains_available < 0) {
          wildcard_domains_available = 0;
        }
        if (parseInt(order.items[0].standard_domains_count) > 0) {
          html += "<div class='domains_available standard'><i>" + (this.helper.t('domains_available.standard')) + " " + standard_domains_available + "</i></div>";
        }
        if (parseInt(order.items[0].wildcard_domains_count) > 0) {
          html += "<div class='domains_available wildcard'><i>" + (this.helper.t('domains_available.wildcard')) + " " + wildcard_domains_available + "</i></div>";
        }
      }
      return html;
    };

    SSLActivationView.prototype.changeCSRWay = function(e) {
      var value;
      value = $(e.currentTarget).val();
      $('div#form_container form', this.container).hide();
      return $("form" + ('#' + value) + "_csr", this.container).show();
    };

    SSLActivationView.prototype.changeSSLServerSoftware = function(e) {
      var radios, ref, selected_value;
      selected_value = $(e.currentTarget).val();
      $('select[name="ssl_server_software_id"]', this.container).val(selected_value);
      radios = $('input:radio[name="way"]', this.container);
      if (ref = parseInt(selected_value), indexOf.call(IIS_ids, ref) >= 0) {
        radios.last().prop({
          checked: 'checked'
        });
        return radios.change().parent().hide();
      } else {
        return radios.parent().show();
      }
    };

    SSLActivationView.prototype.buildProCSRForm = function(data, hidden) {
      var form;
      form = "<form id='pro_csr' class='form' " + (hidden ? 'style=\'display: none;\'' : '') + ">";
      form += this.helper.sslServerSoftwareSelect(data.items[0].ssl_server_software_id) + '</br>';
      form += "<div class='form-group'><label for='csr' class='form-group__label'>" + (this.helper.t('form.csr')) + ":</label><br>";
      if (data.items[0].nomenclature_type === 'ucc') {
        form += this.additionalDomainsAvailableInfo(data);
      }
      form += "<textarea class='form-group__textarea' name='csr' cols='40' rows='12' required>" + (this.helper.formatField(data.items[0].csr)) + "</textarea></div>";
      form += "<div class='form-group'><input type='submit' name='' class='form-group__submit' value='" + (this.helper.t('form.next')) + "' /></div>";
      return form += "</form>";
    };

    SSLActivationView.prototype.buildSimpleCSRForm = function(data, hidden) {
      var form;
      form = "<form id='simple_csr' class='form' " + (hidden ? 'style=\'display: none;\'' : '') + ">";
      form += this.helper.sslServerSoftwareSelect(data.items[0].ssl_server_software_id);
      form += "<div class='form-group'><label for='domain_name' class='form-group__label'>" + (this.helper.t('form.domain')) + "</label>";
      form += "<input name='domain_name' class='form-group__input_text' required  value='" + (this.helper.formatField(data.items[0].domain_name)) + "'/></div>";
      form += this.helper.countrySelect('country_name', data.items[0].country_name || data.country_id);
      if (data.items[0].nomenclature_type === 'ucc') {
        form += "<div class='form-group'><label for='additional_domains' class='form-group__label'>" + (this.helper.t('form.additional_domains')) + "</label><br/>";
        form += this.additionalDomainsAvailableInfo(data);
        form += "<textarea name='additional_domains' class='form-group__textarea' cols='20' rows='5'>" + (this.helper.formatField(data.items[0].additional_domains)) + "</textarea></div>";
      }
      if (data.items[0].validation_type !== 'dv') {
        form += "<div class='form-group'><label for='region_name' class='form-group__label'>" + (this.helper.t('form.region_name')) + "</label>";
        form += "<input name='region_name' class='form-group__input_text' required  value='" + (this.helper.formatField(data.items[0].region_name || data.region_name)) + "'/></div>";
        form += "<div class='form-group'><label for='city_name' class='form-group__label'>" + (this.helper.t('form.city_name')) + "</label>";
        form += "<input name='city_name' class='form-group__input_text' required  value='" + (this.helper.formatField(data.items[0].city_name || data.city_name)) + "'/></div>";
        form += "<div class='form-group'><label for='organization_name' class='form-group__label'>" + (this.helper.t('form.organisation_name')) + "</label>";
        form += "<input name='organization_name' class='form-group__input_text' required  value='" + (this.helper.formatField(data.items[0].organization_name || data.company)) + "'/></div>";
        form += "<div class='form-group'><label for='organizational_unit' class='form-group__label'>" + (this.helper.t('form.organisation_unit')) + "</label>";
        form += "<input name='organizational_unit' class='form-group__input_text' required  value='" + (this.helper.formatField(data.items[0].organizational_unit)) + "'/></div>";
        form += "<div class='form-group'><label for='duns' class='form-group__label'>" + (this.helper.t('form.duns')) + "</label>";
        form += "<input name='duns' class='form-group__input_text'  value='" + (this.helper.formatField(data.items[0].duns)) + "'/></div>";
        form += "<div class='form-group' id='inn_field'><label for='inn' class='form-group__label'>" + (this.helper.t('form.inn')) + "</label>";
        form += "<input name='inn' class='form-group__input_text' required value='" + (this.helper.formatField(data.items[0].inn)) + "'/></div>";
      }
      return form += "<div class='form-group'><input type='submit' class='form-group__submit' name='' value='" + (this.helper.t('form.next')) + "'/></div>";
    };

    SSLActivationView.prototype.renderDCV = function(data, issuer, additional_domains) {
      var dn, domain, email, emails, html, i, j, k, len, len1, len2, level, ref, ref1;
      html = "<div class='heading'><h2 class='heading__title'>" + (this.helper.t('dcv.title')) + "</h2>";
      html += "<p class='heading__description'>" + (this.helper.t('dcv.description')) + "</p></div>";
      html += "<form id='dcv' class='form'>";
      html += "<div class='dcv-item'>";
      html += "<input type='radio' class='form__input_radio' name='dcv_type' value='EMAIL'>" + (this.helper.t('dcv.email.title'));
      html += "<div id='dcv_email' class='dcv-item__input_radio-description' style='padding-left: 25px;'>";
      html += "<p class='dcv-item__subdescription'>" + (this.helper.t('dcv.email.descrtiption')) + "<br><span class='error'></span></p>";
      if (additional_domains.length === 0) {
        ref = data.emails;
        for (level in ref) {
          emails = ref[level];
          if ((emails != null) && emails.length > 0) {
            html += "<h4 class='from__input_radio-group-title'>" + (this.helper.t('dcv.email.' + level)) + ":</h4>";
            for (i = 0, len = emails.length; i < len; i++) {
              email = emails[i];
              html += "<input type='radio' class='form__input_radio' name='dcv_email' value='" + email + "'>" + email + "<br/>";
            }
          }
        }
      } else {
        html += "<div class='multidomain_dcv_table'><div class='multidomain_dcv_table--head'>";
        html += "<div class='multidomain_dcv_table--cell'>" + (this.helper.t('dcv.domain')) + "</div>";
        html += "<div class='multidomain_dcv_table--cell'>" + (this.helper.t('dcv.validation_email')) + "</div></div>";
        html += "<div class='multidomain_dcv_table--row'><div class='multidomain_dcv_table--cell'>" + data.order.domain_name + "</div>";
        html += "<div class='multidomain_dcv_table--cell'>";
        ref1 = data.emails;
        for (level in ref1) {
          emails = ref1[level];
          if ((emails != null) && emails.length > 0) {
            html += "<h4 class='from__input_radio-group-title'>" + (this.helper.t('dcv.email.' + level)) + ":</h4>";
            for (j = 0, len1 = emails.length; j < len1; j++) {
              email = emails[j];
              html += "<input type='radio' class='form__input_radio' name='dcv_email' value='" + email + "'>" + email + "<br/>";
            }
          }
        }
        html += "</div></div>";
        for (k = 0, len2 = additional_domains.length; k < len2; k++) {
          domain = additional_domains[k];
          html += "<div class='multidomain_dcv_table--row' data-domain='" + domain + "'><div class='multidomain_dcv_table--cell'>" + domain + "</div>";
          html += "<div class='multidomain_dcv_table--cell'><div class='spinner-small'></div></div></div>";
          this.getAdditionalDCV(domain);
        }
        html += "</div>";
      }
      html += "</div></div>";
      if (issuer === 'comodo') {
        dn = data.order.domain_name.replace('*.', '');
        html += "<div class='dcv-item'><input type='radio' name='dcv_type' class='form__input_radio' value='CNAME_CSR_HASH'>" + (this.helper.t('dcv.dns.title'));
        html += "<div id='dcv_cname_csr_hash' class='dcv-item__input_radio-description' style='padding-left: 25px;'>";
        html += "<p class='dcv-item__subdescription'>" + (this.helper.t('dcv.dns.description')) + "<br/>";
        html += "<i>_" + data.hashes.md5 + "." + dn + ". CNAME " + (data.hashes.sha1.match(/[\s\S]{1,32}/g) || []).join('.')  + ".sectigo.com.</i>";
        html += "</p>";
        html += (this.helper.t('dcv.instruction')) + " " + data.order.email + "</div></div>";
        html += "<div class='dcv-item'><input type='radio' name='dcv_type' value='HTTP_CSR_HASH'>" + (this.helper.t('dcv.http.title'));
        html += "<div id='dcv_http_csr_hash' class='dcv-item__input_radio-description' style='padding-left: 25px;'>";
        html += "<p class='dcv-item__subdescription'>" + (this.helper.t('dcv.http.description')) + "<br/>";
        html += (this.helper.t('dcv.http.file_name')) + "<br> &nbsp;&nbsp; <i>http://" + dn + "/.well-known/pki-validation/" + data.hashes.md5 + ".txt</i><br>";
        html += ((this.helper.t('dcv.http.file_content')) + "<br>") + ("&nbsp;&nbsp;<i>" + data.hashes.sha1 + "<br>&nbsp;&nbsp;sectigo.com</i>");
        html += "</p>";
        html += (this.helper.t('dcv.instruction')) + " " + data.order.email + "</div></div>";
      }
      html += "<div class='form-group'><input type='submit' class='form-group__submit' id='next' name='' style='dispaly: none;' value='" + (this.helper.t('form.next')) + "'/></div>";
      html += "</form>";
      this.container.html(html);
      $('div.dcv-item__input_radio-description', this.container).hide();
      return $('input:radio[name="dcv_type"]', this.container).change(this.changeDCVType);
    };

    SSLActivationView.prototype.renderFinish = function() {
      var html;
      html = "<div class='heading'><h2 class='heading__title'>" + (this.helper.t('finish.thx')) + "</h2>";
      html += "<p class='heading__description'>" + (this.helper.t('finish.recall')) + "</p></div>";
      return this.container.html(html);
    };

    SSLActivationView.prototype.getAdditionalDCV = function(domain) {
      return $.post(API_URL + "/dcv_emails.json", {
        domain_name: domain
      }, (function(_this) {
        return function(response) {
          var cell, content, email, emails, i, len, level;
          cell = $(".multidomain_dcv_table--row[data-domain='" + domain + "'] .multidomain_dcv_table--cell:last", _this.container);
          content = '';
          for (level in response) {
            emails = response[level];
            if ((emails != null) && emails.length > 0) {
              content += "<h4 class='from__input_radio-group-title'>" + (_this.helper.t('dcv.email.' + level)) + ":</h4>";
              for (i = 0, len = emails.length; i < len; i++) {
                email = emails[i];
                content += "<input type='radio' class='form__input_radio' name='additional_validation_emails[" + domain + "]' value='" + email + "'>" + email + "<br/>";
              }
            }
          }
          return cell.html(content);
        };
      })(this), 'json');
    };

    SSLActivationView.prototype.changeDCVType = function(e) {
      var value;
      value = $(e.currentTarget).val();
      $('span.error').text('');
      $('input#next', this.container).show();
      $('div[id*=dcv_]', this.container).hide();
      return $("div" + ('#dcv_' + value.toLowerCase()), this.container).show();
    };

    SSLActivationView.prototype.createIframe = function(pkey) {
      var iframe;
      iframe = "<iframe src='" + API_URL + "/partners/pkey?file_name=" + pkey + "'";
      iframe += "style='display: none;'></iframe>";
      return this.container.append(iframe);
    };

    SSLActivationView.prototype.showAlert = function(keys, type) {
      $('.alert, .spinner', this.container).remove();
      return this.container.prepend("<div class='alert " + type + "'>" + (this.helper.t(keys)) + "</div>");
    };

    SSLActivationView.prototype.showTextAlert = function(text, type) {
      $('.alert, .spinner', this.container).remove();
      return this.container.prepend("<div class='alert " + type + "'>" + text + "</div>");
    };

    SSLActivationView.prototype.showCriticalError = function(keys) {
      return this.container.html("<div class='alert error'>" + (this.helper.t(keys)) + "</div>");
    };

    SSLActivationView.prototype.appendAlert = function(type, text) {
      return this.container.prepend("<div class='alert " + type + "'>" + text + "</div>");
    };

    SSLActivationView.prototype.clearAlerts = function() {
      $('.alert, .spinner', this.container).remove();
      return $('input:disabled', this.container).prop('disabled', false);
    };

    SSLActivationView.showSystemError = function(text) {
      return $('div#ssl_activation').html("<div class='alert error'>" + text + "</div>");
    };

    return SSLActivationView;

  })();

  SSLActivation = (function() {
    var serializeObject;

    function SSLActivation() {
      this.issue = bind(this.issue, this);
      this.showDCV = bind(this.showDCV, this);
      this.getDCVData = bind(this.getDCVData, this);
      this.updateLead = bind(this.updateLead, this);
      this.additionalDomainsCountValid = bind(this.additionalDomainsCountValid, this);
      this.fetchFromCSR = bind(this.fetchFromCSR, this);
      this.isCertificateDetailsValid = bind(this.isCertificateDetailsValid, this);
      this.generateCSR = bind(this.generateCSR, this);
      this.saveCertificateDetails = bind(this.saveCertificateDetails, this);
      this.onChangeDomainName = bind(this.onChangeDomainName, this);
      this.showCertDetails = bind(this.showCertDetails, this);
      this.isContactInfoValid = bind(this.isContactInfoValid, this);
      this.saveContactInfo = bind(this.saveContactInfo, this);
      this.fetchCertInfo = bind(this.fetchCertInfo, this);
      this.fetchUser = bind(this.fetchUser, this);
      if (this.checkInitialData()) {
        this.setToken();
        this.fetchUser();
      }
    }

    SSLActivation.prototype.checkInitialData = function() {
      var browser;
      if ((browser = this.getBrowser()).name === 'MSIE') {
        if (browser.version < 10) {
          SSLActivationView.showSystemError("You are using an old browser IE " + browser.version + ". Please upgrade your browser to version 10 or higher. Or use other browsers (Chrome, Firefox or Opera).");
          return false;
        }
      }
      if (typeof window.user_token === 'undefined') {
        SSLActivationView.showSystemError('Undefined user public token');
        return false;
      }
      if (typeof window.certificate_token === 'undefined') {
        SSLActivationView.showSystemError('Undefined certificate token');
        return false;
      }
      if (typeof window.lang === 'string') {
        if (window.lang.toLowerCase() === 'en' || window.lang.toLowerCase() === 'ru') {
          this.language = window.lang;
        }
      }
      if (typeof window.top_countries !== 'object') {
        window.top_countries = [];
      }
      if (typeof window.top_countries === 'object') {
        if (!Array.isArray(window.top_countries)) {
          window.top_countries = [];
        }
      }
      if (typeof window.callback_url !== 'string') {
        window.callback_url = '';
      }
      if (typeof window.order !== 'object') {
        window.order = {};
      }
      if (window.order === null) {
        window.order = {};
      }
      return true;
    };

    SSLActivation.prototype.setToken = function() {
      return $.ajaxSetup({
        beforeSend: (function(_this) {
          return function(xhr) {
            xhr.setRequestHeader("X-Public-Token", window.user_token);
            $('div#ssl_activation a, button, input[type="submit"], input[type="button"]').prop({
              disabled: 'true'
            });
            return $('div#ssl_activation').append('<div class="spinner"></div>');
          };
        })(this)
      });
    };

    SSLActivation.prototype.fetchUser = function() {
      return $.getJSON(API_URL + "/users/show.json", (function(_this) {
        return function(data) {
          if (!_this.language) {
            _this.language = data.language;
          }
          _this.view = new SSLActivationView(_this.language);
          return _this.fetchCertInfo();
        };
      })(this)).fail((function(_this) {
        return function(error) {
          return SSLActivationView.showSystemError(error.responseJSON.error);
        };
      })(this));
    };

    SSLActivation.prototype.fetchCertInfo = function() {
      return $.getJSON(API_URL + "/ssl_activation/" + window.certificate_token, (function(_this) {
        return function(data) {
          if (!data.items) {
            return _this.view.showCriticalError('not_found_cert');
          } else {
            _this.certificate = $.extend(data, window.order);
            if (_this.certificate.items[0].nomenclature_type === 'code_signing') {
              return _this.updateLead(_this.issue);
            } else {
              _this.view.setServerSoftwares(_this.certificate.items[0].issuer_name);
              return setTimeout(function() {
                return _this.showCertDetails();
              }, 1000);
            }
          }
        };
      })(this)).error((function(_this) {
        return function(error) {
          if (error.status === 404) {
            return _this.view.showCriticalError('not_found_cert');
          } else {
            return SSLActivationView.showSystemError(error.responseJSON.error);
          }
        };
      })(this));
    };

    SSLActivation.prototype.saveContactInfo = function(e) {
      var form;
      e.preventDefault();
      form = $(e.currentTarget);
      $.extend(this.certificate, serializeObject(form));
      if (this.isContactInfoValid()) {
        return this.updateLead(this.showCertDetails);
      } else {
        return this.view.showAlert('form.error', 'error');
      }
    };

    SSLActivation.prototype.isContactInfoValid = function() {
      var field, fields, i, len;
      fields = ["first_name", "last_name", "phone", "email"];
      for (i = 0, len = fields.length; i < len; i++) {
        field = fields[i];
        if (this.certificate[field] === void 0 || this.certificate[field] === null || this.certificate[field] === '') {
          return false;
        }
      }
      return true;
    };

    SSLActivation.prototype.showCertDetails = function() {
      this.view.renderCertificateDetails(this.certificate);
      $('div#form_container form', this.view.container).submit(this.saveCertificateDetails);
      $('form#simple_csr input[name="domain_name"]', this.view.container).keyup(this.onChangeDomainName);
      return $('form#simple_csr input[name="domain_name"]', this.view.container).keyup();
    };

    SSLActivation.prototype.onChangeDomainName = function(e) {
      var value;
      value = $(e.currentTarget).val();
      $('span.domain_error', this.view.container).remove();
      if (this.certificate.items[0].nomenclature_type === 'standart' || this.certificate.items[0].nomenclature_type === 'ucc') {
        if (value !== '' && value.indexOf('*') === 0) {
          return $(e.currentTarget).after("<span class='error domain_error'>" + (this.view.helper.t('form.no_wildcard_error')) + "</span>");
        }
      }
    };

    SSLActivation.prototype.saveCertificateDetails = function(e) {
      var form;
      e.preventDefault();
      form = $(e.currentTarget);
      $.extend(this.certificate.items[0], serializeObject(form));
      if (form.attr('id') === 'pro_csr') {
        return $.ajax({
          url: API_URL + "/our_ssl_certificates/parse_csr.json",
          type: 'POST',
          crossDomain: true,
          data: {
            csr: this.certificate.items[0].csr
          },
          dataType: 'json',
          success: (function(_this) {
            return function(data) {
              return _this.fetchFromCSR(data);
            };
          })(this),
          error: (function(_this) {
            return function(error) {
              return _this.view.showAlert('form.wrong_csr', 'error');
            };
          })(this)
        });
      } else {
        if (this.certificate.items[0].additional_domains != null) {
          this.certificate.items[0].additional_domains = this.certificate.items[0].additional_domains.trim().split(/\n|\s|,|;/).join(',').replace(/,,/g, ',');
        }
        return this.generateCSR();
      }
    };

    SSLActivation.prototype.generateCSR = function() {
      if (this.isCertificateDetailsValid()) {
        if (this.additionalDomainsCountValid()) {
          if (this.certificate.items[0].nomenclature_type === 'wildcard' && this.certificate.items[0].domain_name.indexOf('*.') !== 0) {
            this.certificate.items[0].domain_name = '*.'.concat(this.certificate.items[0].domain_name);
          }
          return $.ajax({
            url: API_URL + "/ssl_certificates/generate_csr.json",
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: {
              cn: this.certificate.items[0].domain_name,
              c: this.certificate.items[0].country_name,
              s: this.certificate.items[0].region_name,
              l: this.certificate.items[0].city_name,
              o: this.certificate.items[0].organization_name,
              ou: this.certificate.items[0].organizational_unit,
              nomenclature_id: this.certificate.items[0].nomenclature_id,
              additional_domains: this.certificate.items[0].additional_domains
            },
            success: (function(_this) {
              return function(data) {
                _this.certificate.items[0].csr = data.csr;
                _this.view.createIframe(data.pkey);
                return _this.updateLead(_this.getDCVData);
              };
            })(this)
          });
        }
      } else {
        return this.view.showAlert('form.error', 'error');
      }
    };

    SSLActivation.prototype.isCertificateDetailsValid = function() {
      var field, fields, i, len;
      fields = [];
      if (this.certificate.validation_type === 'ov') {
        fields = ['domain_name', 'organization_name', 'organizational_unit', 'country_name', 'city_name', 'region_name', 'ssl_server_software_id'];
        if (this.certificate.items[0].country_name === 'RU') {
          fields.push('inn');
        }
      } else {
        fields = ['country_name', 'domain_name', 'ssl_server_software_id'];
      }
      for (i = 0, len = fields.length; i < len; i++) {
        field = fields[i];
        if (this.certificate.items[0][field] === void 0 || this.certificate.items[0][field] === null || this.certificate.items[0][field] === '') {
          return false;
        }
      }
      return true;
    };

    SSLActivation.prototype.fetchFromCSR = function(data) {
      var item;
      item = {
        domain_name: data.domain_name,
        additional_domains: data.additional_domains,
        country_name: data.country,
        organization_name: data.organization,
        organizational_unit: data.organization_unit,
        region_name: data.state,
        city_name: data.locality
      };
      $.extend(this.certificate.items[0], item);
      if (this.additionalDomainsCountValid()) {
        if (this.isCertificateDetailsValid()) {
          return this.updateLead(this.getDCVData);
        } else {
          return this.view.showAlert('form.error_csr', 'error');
        }
      }
    };

    SSLActivation.prototype.additionalDomainsCountValid = function() {
      var domains, ref, standard_count, valid, wildcard_count;
      if (this.certificate.items[0].additional_domains !== null && this.certificate.items[0].additional_domains !== '') {
        this.view.clearAlerts();
        valid = true;
        domains = this.certificate.items[0].additional_domains.split(',');
        if (ref = this.certificate.items[0].domain_name, indexOf.call(domains, ref) < 0) {
          domains = domains.concat(this.certificate.items[0].domain_name);
        }
        wildcard_count = SSLActivation.countWildcardDomains(domains);
        standard_count = domains.length - wildcard_count;
        if (this.certificate.items[0].wildcard_domains_count !== null && this.certificate.items[0].wildcard_domains_count < wildcard_count) {
          this.view.appendAlert('error', "Wildcard domains count (" + wildcard_count + ") exceeds maximum allowed value " + this.certificate.items[0].wildcard_domains_count + ".");
          valid = false;
        }
        if (this.certificate.items[0].standard_domains_count !== null && this.certificate.items[0].standard_domains_count < standard_count) {
          this.view.appendAlert('error', "Standard domains count (" + standard_count + ") exceeds maximum allowed value " + this.certificate.items[0].standard_domains_count + ".");
          valid = false;
        }
        return valid;
      }
      return true;
    };

    SSLActivation.prototype.updateLead = function(next_func) {
      var data;
      if (this.certificate.items[0].nomenclature_type === 'wildcard' && this.certificate.items[0].domain_name.indexOf('*.') !== 0) {
        this.certificate.items[0].domain_name = '*.'.concat(this.certificate.items[0].domain_name);
      }
      data = {
        order: $.extend(true, {}, this.certificate),
        callback_url: window.callback_url
      };
      delete data.order.items[0].nomenclature_name;
      delete data.order.items[0].nomenclature_type;
      delete data.order.items[0].issuer_name;
      delete data.order.items[0].validation_type;
      return $.ajax({
        url: API_URL + "/ssl_activation/" + window.certificate_token + ".json",
        type: 'POST',
        crossDomain: true,
        dataType: 'JSON',
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: (function(_this) {
          return function() {
            return next_func();
          };
        })(this),
        error: (function(_this) {
          return function(error) {
            return _this.view.showTextAlert(error.responseJSON.errors, 'error');
          };
        })(this)
      });
    };

    SSLActivation.prototype.getDCVData = function() {
      return $.post(API_URL + "/dcv_emails.json", {
        domain_name: this.certificate.items[0].domain_name
      }, (function(_this) {
        return function(response) {
          var dcv_data;
          dcv_data = {
            emails: response
          };
          dcv_data.order = {
            domain_name: _this.certificate.items[0].domain_name,
            email: _this.certificate.email
          };
          if (_this.certificate.items[0].issuer_name === 'comodo') {
            return $.post(API_URL + "/dcv_hashes.json", {
              csr: _this.certificate.items[0].csr
            }, function(data) {
              dcv_data.hashes = data;
              return _this.showDCV(dcv_data);
            }, 'json');
          } else {
            return _this.showDCV(dcv_data);
          }
        };
      })(this), 'json');
    };

    SSLActivation.prototype.showDCV = function(dcv_data) {
      var additional_domains;
      additional_domains = this.certificate.items[0].additional_domains && this.certificate.items[0].additional_domains !== '' ? this.certificate.items[0].additional_domains.split(',') : [];
      this.view.renderDCV(dcv_data, this.certificate.items[0].issuer_name, additional_domains);
      return $('form#dcv', this.view.container).submit((function(_this) {
        return function(e) {
          var form;
          e.preventDefault();
          form = $(e.currentTarget);
          $.extend(_this.certificate.items[0], serializeObject(form));
          if (_this.certificate.items[0].dcv_type === 'EMAIL' && ((_this.certificate.items[0].dcv_email == null) || _this.certificate.items[0].dcv_email === '' || Object.keys(_this.certificate.items[0].additional_validation_emails).length !== additional_domains.length)) {
            return _this.view.showAlert('dcv.email.error', 'error');
          } else {
            return _this.updateLead(_this.issue);
          }
        };
      })(this));
    };

    SSLActivation.prototype.issue = function() {
      return $.ajax({
        url: API_URL + "/ssl_activation/" + window.certificate_token + "/issue.json",
        type: 'POST',
        crossDomain: true,
        dataType: 'JSON',
        data: JSON.stringify({
          callback_url: window.callback_url
        }),
        contentType: 'application/json',
        success: (function(_this) {
          return function() {
            return _this.view.renderFinish();
          };
        })(this),
        error: (function(_this) {
          return function(error) {
            return _this.view.showTextAlert(error.responseJSON.errors, 'error');
          };
        })(this)
      });
    };

    serializeObject = function(object) {
      var array, i, index_quot, len, obj, obj_name, result_obj, sub_obj_name;
      result_obj = {};
      array = object.serializeArray();
      for (i = 0, len = array.length; i < len; i++) {
        obj = array[i];
        if (obj.name.indexOf('[') !== -1 && obj.name.indexOf(']') !== -1) {
          index_quot = obj.name.indexOf('[');
          obj_name = obj.name.slice(0, index_quot);
          sub_obj_name = obj.name.slice(index_quot + 1, -1);
          if (!result_obj[obj_name]) {
            result_obj[obj_name] = {};
          }
          result_obj[obj_name][sub_obj_name] = obj.value || '';
        } else {
          result_obj[obj.name] = obj.value || '';
        }
      }
      return result_obj;
    };

    SSLActivation.prototype.getBrowser = function() {
      var M, tem, ua;
      ua = navigator.userAgent;
      tem = void 0;
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {
          name: 'IE',
          version: tem[1] || ''
        };
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem) {
          return {
            name: 'Opera',
            version: tem[1]
          };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
        M.splice(1, 1, tem[1]);
      }
      return {
        name: M[0],
        version: M[1]
      };
    };

    SSLActivation.countWildcardDomains = function(array) {
      var count, domain, i, len;
      count = 0;
      for (i = 0, len = array.length; i < len; i++) {
        domain = array[i];
        if (domain.trim().indexOf('*.') === 0) {
          count = count + 1;
        }
      }
      return count;
    };

    return SSLActivation;

  })();

  $(function() {
    if ($('div#ssl_activation').length > 0) {
      return new SSLActivation();
    }
  });

}).call(this);
