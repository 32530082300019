$(document).ready(function () {
    $('[data-toggle="popover"]').popover({
        trigger: 'manual',
        html: true,
        animation: false
    })
        .on('mouseenter', function () {
            $(this).popover('show');
            $('.popover').on('mouseleave', function () {
                $(this).popover('show');
            });
        }).on('mouseleave', function () {
        $(this).popover('show');
    });

    $('body').on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

});

