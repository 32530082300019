$(document).ready(function() {
    $(document).on("click",".copy-btn",function() {
        var el = '#copytext1';
        var $tmp = $("<textarea>");
        $("body").append($tmp);
        $tmp.val($(el).text()).select();
        document.execCommand("copy");
        $tmp.remove();

        var copied = $('.copied').text();

        if ($('.copy-btn').text() !== copied) {
            var originalText = $('.copy-btn').text();
            $('.copy-btn').text(copied);
            setTimeout(function() {
                $('.copy-btn').text(originalText);
            }, 1500);
        }
    });
});
