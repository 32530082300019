$(document).on('turbolinks:load', function() {
    setInterval(reloadStatus, 15000);
    setInterval(reloadTime, 60000);

    $('.show-alias').on('click', function (e) {
        e.preventDefault();
        $("#alias").val($(this).data('alias'));
        $("#description").val($(this).data('description'));
        $('#update-alias-modal form').attr('action', $(this).data('url'));
        $('#update-alias-modal').modal('show');
    });

    function reloadStatus () {
        $('#user-servers tbody.server_item tr[data-reload="true"]').click();
    }
    function reloadTime () {
        $('#user-servers tbody.server_item tr[data-timereload="true"]').click();
    }

    $('#user-servers tbody.server_item').on('click', 'tr', function () {
        var serverId = $(this).data('id');

        $.ajax({
            url: '/servers/' + serverId,
            method: 'GET',
            dataType: 'script'
        });
    });

    $('#user-servers tbody.active_server_item').on('click', 'tr', function () {
        var serverId = $(this).data('id');

        $.ajax({
            url: '/servers/' + serverId,
            method: 'GET',
            dataType: 'script'
        });
    });

    setInterval(reloadStatusServer, 15000);
    setInterval(reloadTimeServer, 60000);

    function reloadStatusServer () {
        $('#user-server tbody[data-reload="true"]').click();
    }
    function reloadTimeServer () {
        $('#user-server tbody[data-timereload="true"]').click();
    }

    $('#user-server').on('click', 'tbody', function () {
        var serverId = $(this).data('id');

        $.ajax({
            url: '/servers/' + serverId + '/show_server',
            method: 'GET',
            dataType: 'script'
        });
    });

    $('[data-countdown]').each(function() {
        var $this = $(this), finalDate = $(this).data('countdown');
        $this.countdown(finalDate, function(event) {
            $this.html(event.strftime('%D days %H:%M:%S'));
        });
    });

    $('#disk_image_source_id').on('change', function () {
        var clean_local_drives = $('#disk_image_source_id option:selected').data('clean_local_drives');
        var cuda9 = $('#disk_image_source_id option:selected').data('cuda9');
        var cuda10 = $('#disk_image_source_id option:selected').data('cuda10');
        var cuda11 = $('#disk_image_source_id option:selected').data('cuda11');
        var cuda11_1 = $('#disk_image_source_id option:selected').data('cuda11_1');
        var cuda11_8 = $('#disk_image_source_id option:selected').data('cuda11_8');
        var cuda12_1 = $('#disk_image_source_id option:selected').data('cuda12_1');

        if(clean_local_drives || cuda9 || cuda10) {
            $('#install_advanced').show();
        }
        else {
            $('#install_advanced').hide();
        }
        if(clean_local_drives) {
            $('#clean_drives').prop('disabled', false);
        }
        else {
            $('#clean_drives').prop('disabled', true);
        }

        if(cuda9) {
            $('#drivers_cuda9_p').show();
            $('#drivers_cuda9').prop('disabled', false);
        }
        else{
            $('#drivers_cuda9_p').hide();
            $('#drivers_cuda9').prop('disabled', true);
        }

        if(cuda10) {
            $('#drivers_cuda10_p').show();
            $('#drivers_cuda10').prop('disabled', false);
        }
        else{
            $('#drivers_cuda10_p').hide();
            $('#drivers_cuda10').prop('disabled', true);
        }

        if(cuda11) {
            $('#drivers_cuda11_p').show();
            $('#drivers_cuda11').prop('disabled', false);
        }
        else{
            $('#drivers_cuda11_p').hide();
            $('#drivers_cuda11').prop('disabled', true);
        }
        if(cuda11_1) {
            $('#drivers_cuda11_1_p').show();
            $('#drivers_cuda11_1').prop('disabled', false);
        }
        else{
            $('#drivers_cuda11_1_p').hide();
            $('#drivers_cuda11_1').prop('disabled', true);
        }
                if(cuda11_8) {
            $('#drivers_cuda11_8_p').show();
            $('#drivers_cuda11_8').prop('disabled', false);
        }
        else{
            $('#drivers_cuda11_8_p').hide();
            $('#drivers_cuda11_8').prop('disabled', true);
        }
        if(cuda11_1) {
            $('#drivers_cuda12_1_p').show();
            $('#drivers_cuda12_1').prop('disabled', false);
        }
        else{
            $('#drivers_cuda12_1_p').hide();
            $('#drivers_cuda12_1').prop('disabled', true);
        }


    });
    $('#disk_image_source_id').trigger('change');
});