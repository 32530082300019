$(document).ready(function() {
  function csrGenerator() {
    var findParentNodeByClass = function (el, targetParentClass) {
      // Traverse the DOM up with a while loop
      while (!el.classList.contains(targetParentClass)) {
        // Increment the loop to the parent node
        el = el.parentNode;
        if (!el) {
          return null;
        }
      }
      // At this point, the while loop has stopped and `el` represents the element that has
      // the class you specified in the second parameter of the function `targetParentClass`

      // Then return the matched element
      return el;
    };

    var container = document.getElementById('csrGenerator');
    if (container !== null) {
      var formCsr = container.getElementsByClassName('csr_generator_form')[0];
      var selectValidationType = formCsr.getElementsByClassName('csr_validation_type')[0];
      var inputDomainName = formCsr.getElementsByClassName('csr_domain_name')[0];
      var textAreaAdditionalDomain = formCsr.getElementsByClassName('csr_additional_domains')[0];
      var wrapperAdditionalDomain = findParentNodeByClass(textAreaAdditionalDomain, 'wrapper-additional_domains');
      var evWildCardError = formCsr.getElementsByClassName('js-ev_wildcard_error')[0];
      var nameDomainError = formCsr.getElementsByClassName('js-name_domain_error')[0];
      var infoDomainName = formCsr.getElementsByClassName('js-info-domain')[0];
      var dependedFields = formCsr.getElementsByClassName('csr-validation_depended_fields')[0];
      var allInputsOfdependedFields = dependedFields.getElementsByTagName('input');
      var valueValidation = selectValidationType.value;
      var valueDomainName = inputDomainName.value;
      var PATTERN_DOMAIN = /^(\*\.)?([\w-]+\.)+[\w-]+$/;
      var PATTERN_SEPARATOR = /\s*[\,\s+]\s*/;
      var PATTERN_EV = /^([\w-]+\.)+[\w-]+$/;

      function addChanges() {
        if (valueValidation === 'dv') {
          if (!evWildCardError.classList.contains('visually-hidden')) {
            evWildCardError.classList.add('visually-hidden');
          }
          if (!dependedFields.classList.contains('visually-hidden')) {
            dependedFields.classList.add('visually-hidden');
          }
          for (var i = 0; i < allInputsOfdependedFields.length; i++) {
            var element = allInputsOfdependedFields[i];
            element.value = 'DV';
          }
          if (valueDomainName !== '') {
            if (valueDomainName.indexOf('*') !== -1) {
              wrapperAdditionalDomain.classList.add('visually-hidden');
              textAreaAdditionalDomain.value = '';
            } else {
              if (wrapperAdditionalDomain.classList.contains('visually-hidden')) {
                wrapperAdditionalDomain.classList.remove('visually-hidden');
              }
            }
            if (valueDomainName.match(PATTERN_DOMAIN) !== null) {
              if (!nameDomainError.classList.contains('visually-hidden')) {
                nameDomainError.classList.add('visually-hidden');
              }
              if (inputDomainName.classList.contains('error')) {
                inputDomainName.classList.remove('error');
              }
            } else {
              if (nameDomainError.classList.contains('visually-hidden')) {
                nameDomainError.classList.remove('visually-hidden');
              }
              if (!inputDomainName.classList.contains('error')) {
                inputDomainName.classList.add('error');
              }
            }
          } else {
            if (!nameDomainError.classList.contains('visually-hidden')) {
              nameDomainError.classList.add('visually-hidden');
            }
            if (inputDomainName.classList.contains('error')) {
              inputDomainName.classList.remove('error');
            }
          }
        } else if (valueValidation === 'ov') {
          if (!evWildCardError.classList.contains('visually-hidden')) {
            evWildCardError.classList.add('visually-hidden');
          }
          if (dependedFields.classList.contains('visually-hidden')) {
            dependedFields.classList.remove('visually-hidden');
          }
          for (var k = 0; k < allInputsOfdependedFields.length; k++) {
            var element = allInputsOfdependedFields[k];
            if (element.value === 'DV') {
              element.value = '';
            }
          }
          if (valueDomainName !== '') {
            if (valueDomainName.indexOf('*') !== -1) {
              wrapperAdditionalDomain.classList.add('visually-hidden');
              textAreaAdditionalDomain.value = '';
            } else {
              if (wrapperAdditionalDomain.classList.contains('visually-hidden')) {
                wrapperAdditionalDomain.classList.remove('visually-hidden');
              }
            }
            if (valueDomainName.match(PATTERN_DOMAIN) !== null) {
              if (!nameDomainError.classList.contains('visually-hidden')) {
                nameDomainError.classList.add('visually-hidden');
              }
              if (inputDomainName.classList.contains('error')) {
                inputDomainName.classList.remove('error');
              }
            } else {
              if (nameDomainError.classList.contains('visually-hidden')) {
                nameDomainError.classList.remove('visually-hidden');
              }
              if (!inputDomainName.classList.contains('error')) {
                inputDomainName.classList.add('error');
              }
            }
          } else {
            if (!nameDomainError.classList.contains('visually-hidden')) {
              nameDomainError.classList.add('visually-hidden');
            }
            if (inputDomainName.classList.contains('error')) {
              inputDomainName.classList.remove('error');
            }
          }
        } else if (valueValidation === 'ev') {
          if (!nameDomainError.classList.contains('visually-hidden')) {
            nameDomainError.classList.add('visually-hidden');
          }
          if (wrapperAdditionalDomain.classList.contains('visually-hidden')) {
            wrapperAdditionalDomain.classList.remove('visually-hidden');
          }
          if (dependedFields.classList.contains('visually-hidden')) {
            dependedFields.classList.remove('visually-hidden');
          }
          for (var j = 0; j < allInputsOfdependedFields.length; j++) {
            var element = allInputsOfdependedFields[j];
            if (element.value === 'DV') {
              element.value = '';
            }
          }
          if (valueDomainName !== '') {
            if (valueDomainName.match(PATTERN_EV) === null) {
              evWildCardError.classList.remove('visually-hidden');
              if (!inputDomainName.classList.contains('error')) {
                inputDomainName.classList.add('error');
              }
            } else {
              if (!evWildCardError.classList.contains('visually-hidden')) {
                evWildCardError.classList.add('visually-hidden');
              }
              if (inputDomainName.classList.contains('error')) {
                inputDomainName.classList.remove('error');
              }
            }
          } else {
            if (inputDomainName.classList.contains('error')) {
              inputDomainName.classList.remove('error');
            }
            if (!evWildCardError.classList.contains('visually-hidden')) {
              evWildCardError.classList.add('visually-hidden');
            }
          }
        }
      };
      addChanges();
      textAreaAdditionalDomain.addEventListener('input', function (e) {
        console.log('js-info-domain')
        infoDomainName.classList.remove('d-none');
      });
      textAreaAdditionalDomain.addEventListener('change', function (e) {
        addChanges();
        var string = this.value;
        var separateString = string.split(PATTERN_SEPARATOR);
        for (var index = 0; index < separateString.length; index++) {
          var element = separateString[index];
          if (valueValidation !== 'ev') {
            if (this.value !== '') {
              if (element.match(PATTERN_DOMAIN) !== null) {
                if (!nameDomainError.classList.contains('visually-hidden')) {
                  nameDomainError.classList.add('visually-hidden');
                }
                if (this.classList.contains('error')) {
                  this.classList.remove('error');
                }
              } else {
                if (nameDomainError.classList.contains('visually-hidden')) {
                  nameDomainError.classList.remove('visually-hidden');
                }
                if (!this.classList.contains('error')) {
                  this.classList.add('error');
                }
              }
            } else {
              if (!nameDomainError.classList.contains('visually-hidden')) {
                nameDomainError.classList.add('visually-hidden');
              }
              if (this.classList.contains('error')) {
                this.classList.remove('error');
              }
            }

          } else {
            if (this.value !== '') {
              if (element.match(PATTERN_EV) !== null) {
                if (!evWildCardError.classList.contains('visually-hidden')) {
                  evWildCardError.classList.add('visually-hidden');
                }
                if (this.classList.contains('error')) {
                  this.classList.remove('error');
                }
              } else {
                if (evWildCardError.classList.contains('visually-hidden')) {
                  evWildCardError.classList.remove('visually-hidden');
                }
                if (!this.classList.contains('error')) {
                  this.classList.add('error');
                }
              }
            } else {
              if (!evWildCardError.classList.contains('visually-hidden')) {
                evWildCardError.classList.add('visually-hidden');
              }
              if (this.classList.contains('error')) {
                this.classList.remove('error');
              }
            }

          }

        }
      });
      inputDomainName.addEventListener('input', function () {
        valueDomainName = this.value;
        addChanges();
      });
      selectValidationType.addEventListener('change', function (e) {
        valueValidation = this.value;
        addChanges();
      });

      window.addEventListener('load', function () {
        formCsr.reset();
      });

    }
  };
  csrGenerator();
})

